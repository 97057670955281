<template>
  <el-popover
    v-model="visible"
    placement="bottom"
    width="300"
    trigger="click"
    popper-class="filter-popover"
    @show="onShow"
  >
    <div class="tag-type-filter">
      <el-card>
        <div class="flex justify-between items-center">
          <span class="text-primary-100 font-medium text-[16px]">
            {{ title }}
          </span>
          <MaterialIcon size="16" pointer @click.native="visible = false">close_round</MaterialIcon>
        </div>
        <div class="check-all">
          <BaseElCheckbox :value="observeCheckAll" @change="onSelectAll">全選</BaseElCheckbox>

          <BaseElButton class="clear-btn" type="text" @click="onClear">清除</BaseElButton>
        </div>
        <BaseElCheckboxGroup v-model="localValue">
          <div
            v-for="group in optionGroups"
            :key="group.groupLabel"
            class="check-group"
          >
            <div class="check-list">
              <BaseElCheckbox
                v-for="option in group.options"
                :key="option[valueName]"
                :label="option[valueName]"
              >
                {{ option[labelName] }}
              </BaseElCheckbox>
            </div>
          </div>
        </BaseElCheckboxGroup>
        <div class="text-right">
          <BaseElButton plain class="min-w-[100px]" @click="onApply">套用</BaseElButton>
        </div>
      </el-card>
    </div>
    <BaseElButton slot="reference" class="btn-filter">
      <img :src="toggleIcon" class="mr-2 inline">
      標籤管理
    </BaseElButton>
  </el-popover>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import toggleIcon from '@/assets/icon/toggle.svg'
import { map } from 'lodash'

export default defineComponent({
  name: 'TagTypeFilter',
  props: {
    modelValue: { type: Array, required: true },
    title: { type: String, default: '標籤顯示管理' },
    options: { type: Array, default: () => [] },
    labelName: { type: String, default: 'label' },
    valueName: { type: String, default: 'value' },
    groupName: { type: String, default: '' },
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const visible = ref(false)

    const optionGroups = computed(() => {
      if (props.options.length === 0) return []
      if (!props.groupName) {
        return [{
          groupLabel: '_no-group',
          options: props.options,
        }]
      }
      const mapIndex = { }
      return props.options.reduce((groups, item) => {
        const groupKey = item[props.groupName]
        if (groupKey in mapIndex) {
          const index = mapIndex[groupKey]
          groups[index].options.push(item)
        } else {
          mapIndex[groupKey] = groups.length
          groups.push({
            groupLabel: groupKey,
            options: [item],
          })
        }
        return groups
      }, [])
    })

    const localValue = ref([])

    const onClear = () => {
      localValue.value = []
    }

    const onSelectAll = (value) => {
      if (!value) {
        localValue.value = []
      } else {
        const all = props.options.map(i => i[props.valueName])
        localValue.value = [...all]
      }
    }

    const onApply = () => {
      emit('update:modelValue', [...localValue.value])
      emit('update', formatSaveConfig())
      visible.value = false
    }

    const onShow = () => {
      localValue.value = [...props.modelValue]
    }

    const formatSaveConfig = () => {
      const lastKeys = map(props.options, 'value')
      const enabledDict = {
        lastKeys,
        config: {},
      }

      props.options.forEach(item => {
        if (localValue.value.includes(item.value)) {
          enabledDict.config[item.value] = { enable: true }
        } else enabledDict.config[item.value] = { enable: false }
      })
      return enabledDict
    }

    const observeCheckAll = computed(() => {
      let allCheck = true
      props.options.forEach(option => {
        if (localValue.value.includes(option[props.valueName])) return
        allCheck = false
      })
      return allCheck
    })

    return {
      toggleIcon,
      visible,
      optionGroups,
      localValue,
      onClear,
      onSelectAll,
      onApply,
      onShow,
      observeCheckAll,
    }
  },
})
</script>

<style lang="postcss">
.filter-popover {
  @apply border-0 p-0;
}
</style>
<style lang="scss" scoped>

.tag-type-filter {
  @apply w-[300px];

  .check-all,
  .check-group {
    @apply flex justify-between items-center;
    @apply py-3;
    @apply border-b border-[#767676];
  }

  .check-group {
    &:last-child {
      @apply border-b-0;
    }
  }

  .check-list {
    @apply flex flex-wrap;
    margin: 0 -9px;

    .el-checkbox {
      @apply w-[50%] flex-shrink-0 flex-grow-0 px-[9px] mr-0 mb-[9px];

      &:nth-last-child(1) {
        @apply mb-0;
      }
    }
  }

  :deep(.el-card) {
    .el-card__body {
      @apply p-3;
    }
  }

  :deep(.el-checkbox__input) {
    @apply align-text-top;
  }

  :deep(.el-checkbox__label) {
    @apply w-[120px] truncate;
  }

  .clear-btn {
    @apply underline text-normal font-normal text-danger text-sub p-0;
  }
}

.btn-filter {
  @apply border-[#636363] text-[#767676];

  &:hover,
  &:focus {
    @apply text-[#555] border-[#555] bg-white;
  }
}
</style>
