<template>
  <div class="tag-form">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @opened="onOpenDialog"
      @close="onCloseDialog"
    >
      <BaseElForm
        ref="form"
        label-width="100px"
        label-position="top"
        :model="formData"
        :rules="formRules"
        @submit.prevent
      >
        <BaseElFormItem label="名稱" prop="name">
          <BaseElInput v-model.trim="formData.name" placeholder="請輸入標籤名稱" />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="showDialog = false">
          取消
        </BaseElButton>
        <BaseElButton type="primary" @click="onDialogConfirm">
          {{ dialogButton }}
        </BaseElButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent, computed, toRefs } from 'vue'
import { useTagForm } from './useTagForm'

export default defineComponent({
  name: 'TagForm',
  props: {
    dialogType: { type: String, default: '' },
    show: { type: Boolean, default: false },
    sourceData: { type: Object, default: () => ({}) },
  },
  emits: ['update:show', 'update:sourceData', 'submit'],
  setup (props, { emit }) {
    const showDialog = useVModel(props, 'show', emit)

    const dialogTitle = computed(() => {
      const mapTitle = {
        create: '新增標籤',
        update: '編輯標籤',
      }
      return mapTitle[props.dialogType]
    })
    const dialogButton = computed(() => {
      const mapButton = {
        create: '新增',
        update: '儲存',
      }
      return mapButton[props.dialogType]
    })

    const { sourceData } = toRefs(props)
    const {
      form,
      formData,
      formRules,
      submitForm,
      syncForm,
      resetForm,
      isDataChanged,
    } = useTagForm(sourceData)

    const onCloseDialog = () => {
      resetForm()
      emit('update:sourceData', {})
    }

    const onOpenDialog = () => {
      syncForm()
    }

    const onDialogConfirm = async () => {
      const valid = await submitForm()

      if (!valid) return
      if (!isDataChanged.value) {
        showDialog.value = false
        return
      }
      emit('submit', { ...formData })
    }

    return {
      showDialog,
      dialogTitle,
      dialogButton,
      form,
      formData,
      formRules,
      syncForm,
      resetForm,
      onDialogConfirm,
      onOpenDialog,
      onCloseDialog,
    }
  },
})
</script>

<style lang="scss">
.tag-form {
}
</style>
